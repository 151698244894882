/** @format */

import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import RoutesPage from "./routes/RoutesPage";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-276968114-1');
// ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <div className='App'>
      <RoutesPage />
    </div>
  );
}

export default App;
